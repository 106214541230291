export function copyToClipboard(id: string) {
    const targetId = document.getElementById(id)
    if (targetId !== null) {
        const markup = targetId.innerHTML
        const tempInput = document.createElement('input')
        const body = document.getElementsByTagName('body')[0]
        body.appendChild(tempInput)
        tempInput.setAttribute('value', markup)
        tempInput.select();
        function handler (event: any) {
            event.clipboardData.setData('text/html', tempInput.value);
            event.preventDefault();
            document.removeEventListener('copy', handler, true);
        }
        document.addEventListener('copy', handler, true);
        document.execCommand('copy')
        body.removeChild(tempInput)
    }
}
