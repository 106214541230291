import React, {
    useState,
    createContext,
    useContext,
    ReactNode,
    FormEvent,
} from "react";
import PropTypes from "prop-types";

interface InfoContextProps {
    data: any;
    contacts: any;
    globalData: any;
    updateInfo: any;
    updateContacts: any;
    updateGlobalData: any;
}

interface InfoProviderProps {
    children: ReactNode;
}

export const InfoContext = createContext<Partial<InfoContextProps>>({});

export const InfoProvider = ({ children }: InfoProviderProps) => {
    const [contacts, setContacts] = useState([]);
    const defaultData = {
        logo: "",
        banner: "",
        title: "Ing.",
        firstName: "Ján",
        lastName: "Novák",
        workPosition: "konateľ",
        phoneNumber: "+421 123 456 789",
        email: "jan.novak@finlog.sk",
        adress: {
            street: "Podzámska 36",
            city: "Nové Zámky",
            country: "Slovenská republika",
            zip: "940 02",
        },
        text: "Pred vytlačením emailu, prosím zvážte dopad na životné prostredie. Ďakujem. Please consider the environment before printing this email. Thanks.",
        website: "",
        instagram: "",
        facebook: "",
    };
    const [globalData, setGlobalData] = useState({
        logo: "",
        text: "",
        banner: "",
        website: "",
        facebook: "",
        instagram: "",
    });
    const [state, setState] = useState(defaultData);
    return (
        <InfoContext.Provider
            value={{
                contacts,
                data: state,
                globalData,
                updateInfo: (e: any) => {
                    if (e) {
                        setState({
                            ...state,
                            ...e,
                        });
                    } else {
                        setState(defaultData);
                    }
                },
                updateContacts: (c: any) => {
                    setContacts(c);
                },
                updateGlobalData: (d: any) => {
                    setGlobalData(d);
                },
            }}
        >
            {children}
        </InfoContext.Provider>
    );
};

export const useInfoValue = () => useContext(InfoContext);

InfoProvider.propTypes = {
    children: PropTypes.node,
};
