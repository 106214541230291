import React from 'react';
import styled from 'styled-components';

export const Header = () => {
	return (
		<StyledHeader>
			<h1>Generátor podpisov - FinLOG</h1>
			<p>Použite panel vľavo pre výber kontaktu, následne kliknite nižšie pre skopírovanie podpisu do schránky</p>
		</StyledHeader>
	);
};

const StyledHeader = styled.div`
	margin-bottom: 8rem;
	text-align: center;
	font-size: 14px;
	color: #ffffff;
	h1 {
		font-size: 45px;
		margin: 1rem 0 0;
	}
`;
