import React, { useEffect } from "react";
import styled from "styled-components";
import { Header } from "./Header";
import { Preview } from "./Preview";
import { useInfoValue } from "../../Contexts/InfoContext";
import axios from "axios";

export const Canvas = () => {
    const { updateGlobalData } = useInfoValue();
    useEffect(() => {
        axios
            .get("https://admin.finlog.sk/api/signature-setting?populate=*", {
                // withCredentials: true,
                headers: {
                    Accept: "application/json",
                    // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjQxNTU2MjI5LCJleHAiOjE2NDQxNDgyMjl9.vow-jqYomO66byg0AliFPRrpyPrj40CifviakvBz708`,
                },
                responseType: "json",
                method: "get",
            })
            .then(({ data }) => {
                const cmsData = data.data.attributes;
                updateGlobalData({
                    banner: cmsData.banner && cmsData.banner.data.attributes.url,
                    text: cmsData.text,
                    website: cmsData.website,
                    facebook: cmsData.facebook,
                    instagram: cmsData.instagram,
                });
            });
    }, []);

    return (
        <StyledCanvas>
            <Header />
            <Preview />
        </StyledCanvas>
    );
};

const StyledCanvas = styled.article`
    padding: 1rem 3rem;
    grid-area: canvas;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
