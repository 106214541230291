import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from '../../Common/Button';
import { copyToClipboard } from '../../../utils/copyToClipboard';

interface Props {
    children: ReactNode;
}

export const Envelope = ({ children }: Props) => {
    return (
        <Container>
            <Wrapper>
                <TopBar>
                    <ColorDot color={'rgb(242, 84, 91)'} />
                    <ColorDot color={'rgb(245, 194, 107)'} />
                    <ColorDot color={'rgb(0, 189, 165)'} />
                </TopBar>
                <Header>
                    <span>
                        Komu:&nbsp;
                        <strong>xxx@domena.sk</strong>
                    </span>
                    <span>
                        Predmet:&nbsp;
                        <strong>E-mail s novým podpisom</strong>
                    </span>
                </Header>
                <div>
                    <Body>
                        <SignatureWrapper>
                            {children}
                        </SignatureWrapper>
                    </Body>
                </div>
            </Wrapper>
            <div>
                <ButtonWrapper>
                    <Button onClick={() => copyToClipboard('signatureMarkup')}>
                        Skopírovať podpis do schránky
                    </Button>
                </ButtonWrapper>
            </div>
        </Container>
    );
};

const Container = styled.div`
    width: 80%;
`;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    border-radius: 5px;
`;

const ButtonWrapper = styled.div`
    webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 3rem 0px;
`;

const TopBar = styled.div`
    display: flex;
    flex-direction: row;
    padding: 1rem;
    background-color: rgb(51, 71, 91);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px 8px 0px 0px;
`;

const ColorDot = styled.div`
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    
    ${({ color }) => color && `background-color: ${color};`} 
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: rgb(255, 255, 255);
    background-color: rgb(51, 71, 91);
`;

const Body = styled.div`
    background-color: rgb(255, 255, 255);
    border-radius: 0px 0px 8px 8px;
`;

const SignatureWrapper = styled.div`
    width: 100%;
    padding: 5.625rem 2.5rem;
    position: relative;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 50%;
        height: 1rem;
        width: 1rem;
        opacity: 0;
        border-radius: 50%;
        background-color: rgb(66, 91, 118);
    }
`;
