import React, { ReactNode, MouseEvent } from 'react'
import styled from 'styled-components'

export interface ButtonProps {
	onClick: (e: MouseEvent) => void,
	children: ReactNode,
	secondary?: boolean,
}

export const Button = ({ onClick, children }: ButtonProps) => (
	<StyledButton onClick={onClick}>{children}</StyledButton>
)

export const ButtonSecondary = ({ onClick, children }: ButtonProps) => (
	<StyledButton secondary onClick={onClick}>
		{children}
	</StyledButton>
)

const StyledButton = styled.button<ButtonProps>`
	outline: none;
	border: 1px solid transparent;
	font-family: inherit;
	transition: color 0.1s ease-out, border-color 0.1s ease-out,
		background-color 0.1s ease-out;
	&:hover {
		background-color: rgb(0, 204, 204);
		${props => (props.secondary ? 'color: #fff' : null)}
	}
	&:active {
		background-color: #504a65;
	}
	
	
	padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    background-color: rgb(51, 71, 91);
`
