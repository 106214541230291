import React, { ReactElement } from 'react'
import { useControlValue } from '../../Contexts/ControlContext'
import { Templates } from './Templates';

export const CurrentControls = () => {
	const { data } = useControlValue()
	let currentControl: ReactElement
	if (data.control === 'templates') {
		currentControl = <Templates/>
	}
	return currentControl!
}
