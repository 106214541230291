import React, { ChangeEvent, FormEvent } from "react";
import styled from "styled-components";
import { Description } from "../../Common/Description";
import { InputSelect } from "../../Common/InputSelect";
import { useInfoValue } from "../../Contexts/InfoContext";

export const Templates = () => {
    const { contacts, data, updateInfo } = useInfoValue();
    return (
        <StyledForm>
            <Description>
                Vyberte kontakt, pre ktorý chcete vygenerovať podpis:
            </Description>
            <InputSelect
                label={"Kontakt"}
                name={"contact"}
                value={"test"}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    const el = contacts.find(
                        (c: any) => String(c.id) === e.currentTarget.value
                    );
                    updateInfo(el);
                }}
                choices={contacts || []}
                onBlur={(e) => {
                    console.log(e);
                }}
            />
        </StyledForm>
    );
};

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    animation: flipdown 0.2s ease both;
`;
