import React, { useEffect } from "react";
import styled from "styled-components";
import { CurrentControls } from "./CurrentControls";
import { useInfoValue } from "../../Contexts/InfoContext";
import axios from "axios";

export const Controls = () => {
    const { updateContacts } = useInfoValue();
    useEffect(() => {
        axios
            .get("https://admin.finlog.sk/api/contacts?pagination[page]=1&pagination[pageSize]=500&populate=*", {
                // withCredentials: true,
                headers: {
                    Accept: "application/json",
                    // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjQxNTU2MjI5LCJleHAiOjE2NDQxNDgyMjl9.vow-jqYomO66byg0AliFPRrpyPrj40CifviakvBz708`,
                },
                responseType: "json",
                method: "get",
            })
            .then(({ data }) => {
                updateContacts(data.data.map((d: any) => ({ id: d.id, ...d.attributes })));
            });
    }, []);

    return (
        <StyledControlsContainer>
            <CurrentControls />
        </StyledControlsContainer>
    );
};

const StyledControlsContainer = styled.article`
    grid-area: form;
    display: flex;
    flex-direction: column;
    padding: 2rem 2.5rem 2rem 2rem;
    color: #e8e8e8;
    // background-color: rgb(0, 0, 102);
    background-color: rgb(51, 71, 91);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`;
