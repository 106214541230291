import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Template } from '../../Templates/Template';
import { Envelope } from './Envelope';

export const Preview = () => {
	return (
		<Fragment>
			<Envelope>
				<StyledPreview id="signatureMarkup"><Template /></StyledPreview>
			</Envelope>
		</Fragment>
	);
};

const StyledPreview = styled.article`
	margin-bottom: 3rem;
`;
