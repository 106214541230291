import React, { ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export interface InputTextProps {
	value: string,
	onChange: (e: ChangeEvent<HTMLSelectElement>) => void,
	name: string,
	label: string,
	choices: any,
};

export const InputSelect = ({ value, onChange, name, label, choices }: InputTextProps) => (
	<StyledInputSelect>
		<Select
			required
			defaultValue={value}
			onChange={(e) => onChange(e)}
			name={name}
			key={name}
		>
			<option value={-1} label={'Vybrať'} />
			{choices.map((c: Record<string, any>) => (
				<option value={c.id} label={`${c.firstName} ${c.lastName}`} />
			))}
		</Select>
		<Label htmlFor={name}>{label}</Label>
	</StyledInputSelect>
);

InputSelect.propTypes = {
	value: PropTypes.string,
	onBlur: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
};

const StyledInputSelect = styled.div`
	position: relative;
	margin-top: 3rem;
`;

const Select = styled.select`
	width: 100%;
	height: 30px;
	padding: 0.5rem 0 0.5rem 0.5rem;
	margin-bottom: 3rem;
	font-size: 1.25rem;
	background: transparent;
	color: #e8e8e8;
	border: none;
	border-bottom: 2px solid #e8e8e8;
	box-shadow: none;
	&:focus {
		outline: none;
	}
	&:focus {
		outline: none;
	}
	&:focus + label,
	&:valid + label {
		top: -0.75rem;
		transform: scale(0.8);
		left: 0;
	}
	height: 3rem;
`;

const Label = styled.label`
	position: absolute;
	top: 3rem;
	left: 0.5rem;
	transition: all 0.2s ease-in-out;
	pointer-events: none;
	color: rgb(0, 204, 204);
`;
